import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiDelete, apiPost, apiPut } from 'src/utils/axios';
import {
  AddUserDetails,
  Status,
  LegalServiceProviders,
  LSQueryParams,
  ChartData,
  KeyTeamMemberScoreCard,
  AnalyticsScoreCard,
  FileDetails,
  FileData,
  UserQueryParams,
  EditUserModel,
  CorporateProvider,
  AnalyticsCorporateScoreCard,
  ChangePassword,
  LegalServiceReview,
  AddReviewDetail,
  RFPAbortModel,
  UpdateFavoriteBidderModel
} from 'src/models/form_post';
import {
  postAddUser,
  getCodeDetails,
  getLegalServiceProvider,
  getLegalServiceProviderByCorporate,
  getCorporateChartList,
  getKeyTeamMember,
  getKeyTeamMemberScoreCard,
  getKeyTeamMemberScoreCardGlobal,
  getAnalyticsScoreCard,
  getUserList,
  getFile,
  getRfpWinnerAndLoserUrl,
  getConfigByKeyUrl,
  getProposalByRfpIdUrl,
  putEditUser,
  getCorporateProvider,
  getProposalStatusUrl,
  putPasswordChange,
  getLegalServicesReview,
  postLSPReview,
  deleteTemplateList,
  putRFPAbort,
  getConfigByIdUrl,
  postCorporateSupport,
  getDynamicCorporateContentLSP,
  getKeyTeamMemberByCorporate,
  getCorporateScoreCardWithoutKtm,
  getKeyTeamMemberScoreCardWithKtm,
  getSpecificCorporateAnalyticsScoreCard,
  getCorporateAnalyticsScoreCard,
  putCorporateProfileUpdate,
  putFavoriteBidderUpdate,
  getFavoriteBidders,
  deleteFavoriteBidderList,
  getRfpListAdmin,
  getFavoriteBidderAddFile,
  getFavoriteBidderListFile,
  getFavoriteBidderFile,
  getFavoriteBidderRemoveFile,
  postCuAddMainContactPerson,
  postCuRemoveMainContactPerson,
  getCorporateUserGroupUrl,
  getCorporateUserGroupUserUrl,
  postCorporateUserGroupUrl,
  getCorporateUserGroupDetailUrl,
  putCorporateUserGroupUrl,
  putCorporateUserGroupUpdateStatusUrl,
  deleteCorporateUserGroupUrl,
  removeCorporateUserUserGroupUrl,
  addRemoveAdminCorporateUserUserGroupUrl,
  deleteUserUrl,
  postAiPromptUrl,
  postAiFeedbackPromptUrl,
  getRevisionListUrl,
  postRevisionPromptUrl,
  putRevisionPromptUrl,
  getServicesRatingReviewUrl
  // deleteRFPFileUrl
} from 'src/utils/pathConst';
import {
  RfpParams,
  RfpListModel,
  RfpWinnerAndLoser,
  ConfigByKey,
  ProposalByRfpId,
  ProposalStatusDetails,
  FavouriteListModel,
  FileRemoveModel
} from 'src/models/rfp';
import { apiGet } from 'src/utils/axios';
import { errorHandler } from 'src/utils/errorHandler';
import { getRfpList } from 'src/utils/pathConst';
import { CorporateRequestForMissingInfoModel } from 'src/models/select';
import { SaveNewAiPromptModel } from 'src/models/CorporateModelDeclaration';
import { initialAiPrompt } from 'src/utils/InitialAiPrompt';

export type ErrorInformation = {
  message: string;
  status: number;
};

type CorporateState = {
  loading: boolean;
  error: ErrorInformation;
  corporateData: object;
  corporateStepTwo: object;
  userGroupData: object;
  corporateSpecificValue: object;
  corporateRfpData: any;
  user_details: AddUserDetails;
  reviewDetail: AddReviewDetail;
  rfpListModel: RfpListModel;
  rfpListAdmin: RfpListModel;
  favoriteListModel: FavouriteListModel;
  getStatus: Status;
  getLegalServiceProviders: LegalServiceProviders;
  getLegalServiceProviderByCorporate: LegalServiceProviders;
  legalServicesProviderReview: LegalServiceReview;
  getCorporateService: CorporateProvider;
  getChartData: ChartData;
  getTeamMemberData: Status;
  getTeamMemberByCorporateData: Status;
  getCorporateDynamicData: Status;
  getLSTeamMemberData: Status;
  getScoreCardData: Status;
  getGlobalScoreCardData: Status;
  getUserValues: Status;
  getFileValue: FileData;
  rfpWinnerAndLoser: any;
  rfpSelection: any;
  editUser: EditUserModel;
  abort: RFPAbortModel;
  changePassword: ChangePassword;
  updateUserProfile: any;
  updatefavoriteBidder: any;
  CorporateScoreData: any;
  SpecificCorporateScoreData: any;
  CorporateScoreDataWithoutKtm: any;
  deleteTemplate: any;
  initialAiPromptVal: any;
  // initialMinimizeCount: number;
  revisionList: any;
  servicesReviewRating: any;
  revisionDetail: any;
  showSection: any;
};

const initialState: CorporateState = {
  loading: true,
  error: null,
  rfpListModel: null,
  rfpListAdmin: null,
  favoriteListModel: null,
  corporateData: {
    role: 'CU',
    company_name: '',
    logo: {
      file: []
    },
    first_name: '',
    surname: '',
    address: '',
    country: '',
    city: '',
    zip: '',
    phone_number: '',
    email: '',
    headquarters_country_id: '',
    headquarters_comments: '',
    file: null
  },
  corporateStepTwo: {
    industry_id: '',
    industry_comments: '',
    company_size_id: '',
    department_size_id: '',
    spend_id: null,
    rfp_spend_id: null,
    comments: '',
    terms_of_services: false,
    how_you_hear_about_navan_legal: {
      value: '',
      comment: ''
    },
    otherValue: '',
    logo: {
      file: [
        {
          actual_name: '',
          type: '',
          internal_name: '',
          status: ''
        }
      ]
    }
  },
  corporateSpecificValue: {
    is_restricted: false
  },
  initialAiPromptVal: initialAiPrompt,
  // initialMinimizeCount: 0,
  userGroupData: {
    selectedValue: ''
  },
  showSection: {
    accredited: true,
    nonAccredited: false,
    biddersInfo: false
  },
  corporateRfpData: '',
  user_details: null,
  reviewDetail: null,
  getStatus: null,
  getLegalServiceProviders: null,
  getLegalServiceProviderByCorporate: null,
  legalServicesProviderReview: null,
  getCorporateService: null,
  getChartData: null,
  getTeamMemberData: null,
  getTeamMemberByCorporateData: null,
  getCorporateDynamicData: null,
  getLSTeamMemberData: null,
  getScoreCardData: null,
  getGlobalScoreCardData: null,
  getUserValues: null,
  getFileValue: null,
  rfpWinnerAndLoser: null,
  rfpSelection: null,
  editUser: null,
  abort: null,
  changePassword: null,
  updateUserProfile: null,
  updatefavoriteBidder: null,
  CorporateScoreData: null,
  SpecificCorporateScoreData: null,
  CorporateScoreDataWithoutKtm: null,
  deleteTemplate: null,
  revisionList: null,
  servicesReviewRating: null,
  revisionDetail: null
};

export const PostEmail = createAsyncThunk<
  AddUserDetails,
  { user_details: any },
  {
    rejectValue: ErrorInformation;
  }
>('post/user', async ({ user_details }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postAddUser}`, user_details);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const PostLSPReview = createAsyncThunk<
  AddReviewDetail,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('post/reviewDetail', async (params: any, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postLSPReview}`, params);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getStatus = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCodeDetails', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCodeDetails}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getDynamicCorporateContentPreview = createAsyncThunk<
  Status,
  { corporateId? },
  {
    rejectValue: ErrorInformation;
  }
>('select/getDynamicContentLSP', async ({ corporateId }, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getDynamicCorporateContentLSP}/${corporateId}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getUserData = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getUserList', async (params: UserQueryParams, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(
      `${getUserList}/${params.corporate_id}?limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&sortDir=${params.sortDir}&sortBy=${params.sortBy}`
    );
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalServicesProviderReview = createAsyncThunk<
  LegalServiceReview,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServicesReview', async (params: LSQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalServicesReview}?corporate_id=${params.corporate_id}&offset=${params.offset}&limit=${params.limit}&search_string=${params.search_string}&rating=${params.rating}&add_review=${params.add_review}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalServicesProviderReviewView = createAsyncThunk<
  LegalServiceReview,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServicesProviderReviewView', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getLegalServicesReview}/${params.id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateService = createAsyncThunk<
  CorporateProvider,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateProvider', async (params: LSQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getCorporateProvider}?&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&status=${params.status}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Basic Key Team Member
export const getTeamMember = createAsyncThunk<
  Status,
  { legal_service_id? },
  {
    rejectValue: ErrorInformation;
  }
>('select/getKeyTeamMember', async ({ legal_service_id }, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getKeyTeamMember}/${legal_service_id}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
//Todo: Specific Key Team Member
export const getTeamMemberByCorporate = createAsyncThunk<
  Status,
  { legal_service_id?; corporate_id },
  {
    rejectValue: ErrorInformation;
  }
>('select/getKeyTeamMemberByCorporate', async ({ legal_service_id, corporate_id }, { rejectWithValue }) => {
  try {
    const getTeamResponse = await apiGet(`${getKeyTeamMemberByCorporate}/${legal_service_id}/${corporate_id}`);
    return getTeamResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Basic Legal Service Providers
export const getLegalService = createAsyncThunk<
  LegalServiceProviders,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServiceProvider', async (params: LSQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalServiceProvider}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&status=${params.status}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Specific Legal Service Provider By Corporate
export const getLegalServiceByCorporate = createAsyncThunk<
  LegalServiceProviders,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getLegalServiceProviderByCorporate', async (params: LSQueryParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getLegalServiceProviderByCorporate}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&search_string=${params.search_string}&status=${params.status}&corporate_id=${params.corporate_id}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Basic Score Card
export const getScoreCard = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/KeyTeamMemberScoreCard', async (params: KeyTeamMemberScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getKeyTeamMemberScoreCard}?user_id=${params.user_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Specific Global Score Card
export const getGlobalScoreCard = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/KeyTeamMemberScoreCard', async (params: KeyTeamMemberScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getKeyTeamMemberScoreCardGlobal}?legal_service_id=${params.legal_service_id}&user_id=${params.user_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Corporate Score Card with Key Team Member
export const getScoreCardWithKtm = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getKeyTeamMemberScoreCardWithKtm', async (params: KeyTeamMemberScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getKeyTeamMemberScoreCardWithKtm}?user_id=${params.user_id}&legal_service_id=${params.legal_service_id}&corporate_id=${params.corporate_id}`
    );
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Corporate Score Card without Key Team Member
export const getScoreCardWithoutKtm = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateAnalyticsScoreCardWithoutKtm', async (params: AnalyticsScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateScoreCardWithoutKtm}?legal_service_id=${params.legal_service_id}&corporate_id=${params.corporate_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getLegalScoreCard = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getAnalyticsScoreCard', async (params: AnalyticsScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getAnalyticsScoreCard}?legal_service_id=${params.legal_service_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getCorporateScoreCard = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateAnalyticsScoreCard', async (params: AnalyticsCorporateScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateAnalyticsScoreCard}?corporate_id=${params.corporate_id}`);

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo :Specific Team Change Score Card
export const getSpecificCorporateScoreCard = createAsyncThunk<
  Status,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getSpecificCorporateAnalyticsScoreCard', async (params: AnalyticsCorporateScoreCard, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getSpecificCorporateAnalyticsScoreCard}?legal_service_id=${params.legal_service_id}&corporate_id=${params.corporate_id}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getChartDetails = createAsyncThunk<
  LegalServiceProviders,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getCorporateChartList', async (params: ChartData, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getCorporateChartList}?corporate_id=${params.corporate_id}&legal_service_id=${params.legal_service_id}&status=${params.status}&from_date=${params.from_date}&to_date=${params.to_date}`
    );

    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRfpListview = createAsyncThunk<
  RfpListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getRfpListview', async (params: RfpParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getRfpList}/${params.corporate_id}?search_string=${params.search_string}&sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&is_dashboard=${params.is_dashboard}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
//TODO: Admin RFP
export const getRfpAdminList = createAsyncThunk<
  RfpListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getRfpAdminList', async (params: RfpParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getRfpListAdmin}?search_string=${params?.search_string}&sortBy=${params?.sortBy}&sortDir=${params?.sortDir}&limit=${params?.limit}&offset=${params?.offset}&status=${params?.status}`
    );
    return getResponse?.data?.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
//Todo : Favourite Bidders List Api
export const getFavouriteBidderListview = createAsyncThunk<
  FavouriteListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/FavouriteListModel', async (params: RfpParams, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getFavoriteBidders}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&search_string=${params.search_string}&corporate_id=${params.corporate_id}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getFavouriteBidderAdd = createAsyncThunk<
  Status,
  { queryParam: any; file: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getFavouriteBidderAdd', async ({ queryParam, file }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${getFavoriteBidderAddFile}/${queryParam?.corporate_id}`, file);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getAddMainContactPerson = createAsyncThunk<
  Status,
  { body: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getAddMainContactPerson', async ({ body }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postCuAddMainContactPerson}`, body);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const getRemoveMainContactPerson = createAsyncThunk<
  Status,
  { body: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getRemoveMainContactPerson', async ({ body }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postCuRemoveMainContactPerson}`, body);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Favourite Bidders list file Api

export const getFavouriteBidderGetView = createAsyncThunk<
  Status,
  { corporate_id? },
  {
    rejectValue: ErrorInformation;
  }
>('select/getFavouriteBidderGetView', async ({ corporate_id }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getFavoriteBidderListFile}/${corporate_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Favourite Bidders get file Api
export const getFavouriteBidderGetFileList = createAsyncThunk<
  FileRemoveModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getFavouriteBidderGetList', async (File: FileRemoveModel, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${getFavoriteBidderFile}`, File);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Favourite Bidders Remove file Api
export const getFavouriteBidderRemoveFileList = createAsyncThunk<
  FileRemoveModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getFavouriteBidderRemoveFileList', async (file: FileRemoveModel, { rejectWithValue }) => {
  try {
    const getResponse = await apiDelete(`${getFavoriteBidderRemoveFile}`, file);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Add Favourite Bidders List Api
export const putUpdateFavoriteBidder = createAsyncThunk<
  UpdateFavoriteBidderModel,
  { data: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/putUpdateFavoriteBidder', async ({ data }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${putFavoriteBidderUpdate}`, data);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo: Remove Favourite Bidders List Api
export const removeFavoriteBidder = createAsyncThunk<
  UpdateFavoriteBidderModel,
  { data: any },
  {
    rejectValue: ErrorInformation;
  }
>('select/removeFavoriteBidder', async ({ data }, { rejectWithValue }) => {
  try {
    const getResponse = await apiDelete(`${deleteFavoriteBidderList}`, data);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getFileDetails = createAsyncThunk<
  FileData,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/getFile', async (params: FileDetails, { rejectWithValue }) => {
  try {
    const getFileResponse = await apiGet(`${getFile}?key=${params.key}&actual_name=${params.actual_name}`);

    return getFileResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRfpWinnerAndLoser = createAsyncThunk<
  RfpWinnerAndLoser,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getRfpWinnerAndLoser', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRfpWinnerAndLoserUrl}/${params.rfp_id}`);
    return getResponse.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalByRfpId = createAsyncThunk<
  ProposalByRfpId,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getProposalByRfpId', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getProposalByRfpIdUrl}/${params.rfp_id}`);
    return getResponse.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getConfigById = createAsyncThunk<
  ProposalByRfpId,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getConfigById', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getConfigByIdUrl}/?key=${params.rfp_id}`);
    return getResponse.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getProposalStatus = createAsyncThunk<
  ProposalStatusDetails,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getProposalStatusDetails', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getProposalStatusUrl}/${params.corporate_id}/${params.rfp_id}/proposal-status`);
    return getResponse.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getConfigByKey = createAsyncThunk<
  ConfigByKey,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getConfigByKey', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getConfigByKeyUrl}?key=${params.key}`);
    return getResponse.data.detail;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putUserEdit = createAsyncThunk<
  EditUserModel,
  { queryParam; editUser },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putUserEdit', async ({ queryParam, editUser }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putEditUser}/${queryParam}`, editUser);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putRFPListAbort = createAsyncThunk<
  RFPAbortModel,
  { params; abort },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putRFPListAbort', async ({ params, abort }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putRFPAbort}/${params.corporate_id}/${params.rfp_id}`, abort);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putUpdatePassword = createAsyncThunk<
  EditUserModel,
  { changePassword },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putUpdatePassword', async ({ changePassword }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putPasswordChange}`, changePassword);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putUpdateUserProfile = createAsyncThunk<
  EditUserModel,
  { corporate_id; user_id; formValue },
  {
    rejectValue: ErrorInformation;
  }
>('select/putCorporateProfileUpdate', async ({ corporate_id, user_id, formValue }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putCorporateProfileUpdate}/${corporate_id}/${user_id}`, formValue);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteTemplateListView = createAsyncThunk<
  Status,
  { queryParam: any; deleteTemplate: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/deleteTemplateListView', async ({ queryParam, deleteTemplate }, { rejectWithValue }) => {
  try {
    const getResponse = await apiDelete(`${deleteTemplateList}/${queryParam?.rfp_template_bank_id}`, deleteTemplate);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const corporateFaqSendMessage = createAsyncThunk<
  CorporateRequestForMissingInfoModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('select/CorporateRequestForMissingInfoModel', async (params, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postCorporateSupport}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateUserGroup = createAsyncThunk<
  FavouriteListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getCorporateUserGroup', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getCorporateUserGroupUrl}/${params.corporate_id}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&search_string=${params.search_string}&corporate_id=${params.corporate_id}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateUserGroupDetail = createAsyncThunk<
  FavouriteListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getCorporateUserGroupDetail', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getCorporateUserGroupDetailUrl}/${params.userGroupId}`);
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getCorporateUserGroupUser = createAsyncThunk<
  FavouriteListModel,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getCorporateUserGroupUser', async (params: any, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(
      `${getCorporateUserGroupUserUrl}/${params.corporate_id}?sortBy=${params.sortBy}&sortDir=${params.sortDir}&limit=${params.limit}&offset=${params.offset}&status=${params.status}&search_string=${params.search_string}&corporate_id=${params.corporate_id}`
    );
    return getResponse.data.detail[0];
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postCorporateUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/postCorporateUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postCorporateUserGroupUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putCorporateUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putCorporateUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putCorporateUserGroupUrl}/${bodyParams.userGroupId}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putCorporateUserGroupUpdateStatus = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putCorporateUserGroupUpdateStatus', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${putCorporateUserGroupUpdateStatusUrl}/${bodyParams.userGroupId}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteCorporateUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/deleteCorporateUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteCorporateUserGroupUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const removeCorporateUserUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/removeCorporateUserUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${removeCorporateUserUserGroupUrl}/${bodyParams.user_group_id}/${bodyParams.member_id}`, {});
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const addRemoveAdminCorporateUserUserGroup = createAsyncThunk<
  AddUserDetails,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/addRemoveAdminCorporateUserUserGroup', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPut(`${addRemoveAdminCorporateUserUserGroupUrl}/${bodyParams.user_group_id}/${bodyParams.member_id}`, {});
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const deleteCorporateUser = createAsyncThunk<
  any,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/deleteCorporateUser', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiDelete(`${deleteUserUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

//Todo : Post AI Prompt
export const postAiPrompt = createAsyncThunk<
  any,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/postAiPrompt', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postAiPromptUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const postAiFeedbackPrompt = createAsyncThunk<
  any,
  { bodyParams: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/postAiFeedbackPrompt', async ({ bodyParams }, { rejectWithValue }) => {
  try {
    const response = await apiPost(`${postAiFeedbackPromptUrl}`, bodyParams);
    return response.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const getRevisionList = createAsyncThunk<
  any,
  {},
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getRevisionList', async ({}, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getRevisionListUrl}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});
export const postRevisionPrompt = createAsyncThunk<
  any,
  { promptValues: SaveNewAiPromptModel },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/postRevisionPrompt', async ({ promptValues }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPost(`${postRevisionPromptUrl}`, promptValues);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const putRevisionPrompt = createAsyncThunk<
  any,
  { params: SaveNewAiPromptModel },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/putRevisionPrompt', async ({ params }, { rejectWithValue }) => {
  try {
    const getResponse = await apiPut(`${putRevisionPromptUrl}/${params.revision_id}`, params);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

// export const deleteRFPFile = createAsyncThunk<
//   any,
//   { key: any },
//   {
//     rejectValue: ErrorInformation;
//   }
// >('Corporateconnect/deleteRFPFile', async (key, { rejectWithValue }) => {
//   try {
//     const getResponse = await apiDelete(`${deleteRFPFileUrl}`, key);
//     return getResponse.data;
//   } catch (error) {
//     return rejectWithValue(errorHandler(error));
//   }
// });

export const getServicesRatingReview = createAsyncThunk<
  any,
  { queryParam: any },
  {
    rejectValue: ErrorInformation;
  }
>('Corporateconnect/getServicesRatingReview', async ({ queryParam }, { rejectWithValue }) => {
  try {
    const getResponse = await apiGet(`${getServicesRatingReviewUrl}/${queryParam.legal_service_provider_review_id}`);
    return getResponse.data;
  } catch (error) {
    return rejectWithValue(errorHandler(error));
  }
});

export const CorporateSlice = createSlice({
  name: 'Corporateconnect',
  initialState,
  reducers: {
    updateCorporateFormStepOne: (state, action: PayloadAction<any>) => {
      state.corporateData = action.payload;
    },
    updateCorporateFormStepTwo: (state, action: PayloadAction<any>) => {
      state.corporateStepTwo = action.payload;
    },
    updateCorporateSpecificValue: (state, action: PayloadAction<any>) => {
      state.corporateSpecificValue = action.payload;
    },
    updateCorporateUserGroupState: (state, action: PayloadAction<any>) => {
      state.userGroupData = action.payload;
    },
    updateInitialPromptAiState: (state, action: PayloadAction<any>) => {
      state.initialAiPromptVal = action.payload;
    },
    // updatedMinimizeCount: (state, action: PayloadAction<any>) => {
    //   state.initialMinimizeCount = action.payload;
    // },
    setShowSection: (state, action: PayloadAction<any>) => {
      state.showSection = action.payload;
    },
    resetValue: (state) => {
      state.corporateData = {
        role: 'CU',
        company_name: '',
        logo: {
          file: []
        },
        first_name: '',
        surname: '',
        address: '',
        country: '',
        city: '',
        zip: '',
        phone_number: '',
        email: '',
        headquarters_country_id: '',
        headquarters_comments: '',
        how_you_hear_about_navan_legal: {
          value: '',
          comment: ''
        },
        industry_id: '',
        industry_comments: '',
        company_size_id: '',
        department_size_id: '',
        spend_id: '',
        rfp_spend_id: '',
        comments: '',
        terms_of_services: false
      };
      state.corporateSpecificValue = {
        is_restricted: false
      };
      state.userGroupData = {
        selectedValue: ''
      };
      state.showSection = {
        accredited: true,
        nonAccredited: false,
        biddersInfo: false
      };
      state.initialAiPromptVal = initialAiPrompt;
    }
  },
  extraReducers: {
    //PostGa invite slice
    [PostEmail.pending.type]: (state) => {
      state.loading = true;
    },
    [PostEmail.fulfilled.type]: (state, action: PayloadAction<AddUserDetails | null>) => {
      state.user_details = action.payload;
      state.loading = false;
    },
    [PostEmail.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [PostLSPReview.pending.type]: (state) => {
      state.loading = true;
    },
    [PostLSPReview.fulfilled.type]: (state, action: PayloadAction<AddReviewDetail | null>) => {
      state.reviewDetail = action.payload;
      state.loading = false;
    },
    [PostLSPReview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getRfpListview.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpListview.fulfilled.type]: (state, action: PayloadAction<RfpListModel | null>) => {
      state.rfpListModel = action.payload;
      state.loading = false;
    },
    [getRfpListview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getRfpAdminList.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpAdminList.fulfilled.type]: (state, action: PayloadAction<RfpListModel | null>) => {
      state.rfpListAdmin = action.payload;
      state.loading = false;
    },
    [getRfpAdminList.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getFavouriteBidderListview.pending.type]: (state) => {
      state.loading = true;
    },
    [getFavouriteBidderListview.fulfilled.type]: (state, action: PayloadAction<FavouriteListModel | null>) => {
      state.favoriteListModel = action.payload;
      state.loading = false;
    },
    [getFavouriteBidderListview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getStatus.pending.type]: (state) => {
      state.loading = true;
    },
    [getStatus.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getStatus = action.payload;
      state.loading = false;
    },
    [getStatus.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getTeamMember.pending.type]: (state) => {
      state.loading = true;
    },
    [getTeamMember.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getTeamMemberData = action.payload;
      state.loading = false;
    },
    [getTeamMember.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getTeamMemberByCorporate.pending.type]: (state) => {
      state.loading = true;
    },
    [getTeamMemberByCorporate.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getTeamMemberByCorporateData = action.payload;
      state.loading = false;
    },
    [getTeamMemberByCorporate.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getDynamicCorporateContentPreview.pending.type]: (state) => {
      state.loading = true;
    },
    [getDynamicCorporateContentPreview.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getCorporateDynamicData = action.payload;
      state.loading = false;
    },
    [getDynamicCorporateContentPreview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getUserData.pending.type]: (state) => {
      state.loading = true;
    },
    [getUserData.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getUserValues = action.payload;
      state.loading = false;
    },
    [getUserData.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getScoreCard.pending.type]: (state) => {
      state.loading = true;
    },
    [getScoreCard.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getScoreCardData = action.payload;
      state.loading = false;
    },
    [getScoreCard.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getGlobalScoreCard.pending.type]: (state) => {
      state.loading = true;
    },
    [getGlobalScoreCard.fulfilled.type]: (state, action: PayloadAction<Status | null>) => {
      state.getGlobalScoreCardData = action.payload;
      state.loading = false;
    },
    [getGlobalScoreCard.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLegalService.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalService.fulfilled.type]: (state, action: PayloadAction<LegalServiceProviders | null>) => {
      state.getLegalServiceProviders = action.payload;
      state.loading = false;
    },
    [getLegalService.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLegalServiceByCorporate.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalServiceByCorporate.fulfilled.type]: (state, action: PayloadAction<LegalServiceProviders | null>) => {
      state.getLegalServiceProviderByCorporate = action.payload;
      state.loading = false;
    },
    [getLegalServiceByCorporate.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getLegalServicesProviderReview.pending.type]: (state) => {
      state.loading = true;
    },
    [getLegalServicesProviderReview.fulfilled.type]: (state, action: PayloadAction<LegalServiceReview | null>) => {
      state.legalServicesProviderReview = action.payload;
      state.loading = false;
    },
    [getLegalServicesProviderReview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getCorporateService.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporateService.fulfilled.type]: (state, action: PayloadAction<CorporateProvider | null>) => {
      state.getCorporateService = action.payload;
      state.loading = false;
    },
    [getCorporateService.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getChartDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getChartDetails.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.getChartData = action.payload;
      state.loading = false;
    },
    [getChartDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getCorporateScoreCard.pending.type]: (state) => {
      state.loading = true;
    },
    [getCorporateScoreCard.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.CorporateScoreData = action.payload;
      state.loading = false;
    },
    [getCorporateScoreCard.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getSpecificCorporateScoreCard.pending.type]: (state) => {
      state.loading = true;
    },
    [getSpecificCorporateScoreCard.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.SpecificCorporateScoreData = action.payload;
      state.loading = false;
    },
    [getSpecificCorporateScoreCard.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getScoreCardWithoutKtm.pending.type]: (state) => {
      state.loading = true;
    },
    [getScoreCardWithoutKtm.fulfilled.type]: (state, action: PayloadAction<ChartData | null>) => {
      state.CorporateScoreDataWithoutKtm = action.payload;
      state.loading = false;
    },
    [getScoreCardWithoutKtm.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getFileDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [getFileDetails.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.getFileValue = action.payload;
      state.loading = false;
    },
    [getFileDetails.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getRfpWinnerAndLoser.pending.type]: (state) => {
      state.loading = true;
    },
    [getRfpWinnerAndLoser.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.rfpWinnerAndLoser = action.payload;
      state.loading = false;
    },
    [getRfpWinnerAndLoser.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getProposalByRfpId.pending.type]: (state) => {
      state.loading = true;
    },
    [getProposalByRfpId.fulfilled.type]: (state, action: PayloadAction<FileData | null>) => {
      state.rfpSelection = action.payload;
      state.loading = false;
    },
    [getProposalByRfpId.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [putUserEdit.pending.type]: (state) => {
      state.loading = true;
    },
    [putUserEdit.fulfilled.type]: (state, action: PayloadAction<EditUserModel | null>) => {
      state.editUser = action.payload;
      state.loading = false;
    },
    [putUserEdit.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [putRFPListAbort.pending.type]: (state) => {
      state.loading = true;
    },
    [putRFPListAbort.fulfilled.type]: (state, action: PayloadAction<RFPAbortModel | null>) => {
      state.abort = action.payload;
      state.loading = false;
    },
    [putRFPListAbort.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [putUpdatePassword.pending.type]: (state) => {
      state.loading = true;
    },
    [putUpdatePassword.fulfilled.type]: (state, action: PayloadAction<ChangePassword | null>) => {
      state.changePassword = action.payload;
      state.loading = false;
    },
    [putUpdatePassword.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [putUpdateUserProfile.pending.type]: (state) => {
      state.loading = true;
    },
    [putUpdateUserProfile.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.updateUserProfile = action.payload;
      state.loading = false;
    },
    [putUpdateUserProfile.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [putUpdateFavoriteBidder.pending.type]: (state) => {
      state.loading = true;
    },
    [putUpdateFavoriteBidder.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.updatefavoriteBidder = action.payload;
      state.loading = false;
    },
    [putUpdateFavoriteBidder.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },

    [deleteTemplateListView.pending.type]: (state) => {
      state.loading = true;
    },
    [deleteTemplateListView.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.deleteTemplate = action.payload;
      state.loading = false;
    },
    [deleteTemplateListView.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getRevisionList.pending.type]: (state) => {
      state.loading = true;
    },
    [getRevisionList.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.revisionList = action.payload;
      state.loading = false;
    },
    [getRevisionList.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getServicesRatingReview.pending.type]: (state) => {
      state.loading = true;
    },
    [getServicesRatingReview.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.servicesReviewRating = action.payload;
      state.loading = false;
    },
    [getServicesRatingReview.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    },
    [putRevisionPrompt.pending.type]: (state) => {
      state.loading = true;
    },
    [putRevisionPrompt.fulfilled.type]: (state, action: PayloadAction<any | null>) => {
      state.revisionDetail = action.payload;
      state.loading = false;
    },
    [putRevisionPrompt.rejected.type]: (state, action: PayloadAction<ErrorInformation | null>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  resetValue,
  // updatedMinimizeCount,
  updateCorporateFormStepOne,
  updateCorporateUserGroupState,
  updateCorporateFormStepTwo,
  updateCorporateSpecificValue,
  updateInitialPromptAiState,
  setShowSection
} = CorporateSlice.actions;

export default CorporateSlice;
